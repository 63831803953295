.Header {
  display: flex;
  width: 100%;
}
.tenant-selection {
  width: 200px;
  z-index: 100;
}

.user-selection {
  z-index: 100;
}

.report-selection {
  z-index: 100;
}

.type-selection {
  z-index: 100;
}
