.ck-content {
  min-height: 200px;
}

.form-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.form-title {
  font-size: 18px;
  font-weight: lighter;
  text-decoration-line: underline;
}
